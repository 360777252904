
import React, {useContext, useEffect, useState} from "react";
import { ContentLayout, BaseHeaderLayout } from "@strapi/design-system";
import { GlobalContextState } from "../../context/globalContext";
import Review from './Chart/Review';
import { baseUrl } from "../../config";
import qs from 'qs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { highlightSQL } from '../../utils/sqlHighlighter';

export default function DashboardView() {
  const user = useContext(GlobalContextState).user;
  const token = useContext(GlobalContextState).token;
  const [starsReview, setStarsReview] = useState([0, 0, 0, 0, 0]);
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [crystalBallQueryResult, setCrystalBallQueryResult] = useState(null);

  useEffect(() => {
    const initFetchData = async () => {
      await fetchFormData();
    };

    initFetchData();
  }, []);

  const fetchFormData = async () => {
    const reviewUrl = `${baseUrl}/api/chat-sessions`;

    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const query = qs.stringify({
        filters: {
          reviewCount: {
            $gt: 0,
          },
        },
      }, {
        encodeValuesOnly: true,
      });

      const fetchUrl = `${reviewUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        let rate1 = 0;
        let rate2 = 0;
        let rate3 = 0;
        let rate4 = 0;
        let rate5 = 0;
        // starsReview format: [1, 2, 3, 4, 5]
        const data = await response.json();
        const allReviews = data.data;
        allReviews.forEach(review => {
          if(review.attributes.reviewCount === 1){
            rate1++;
          } else if(review.attributes.reviewCount === 2){
            rate2++;
          } else if(review.attributes.reviewCount === 3){
            rate3++;
          } else if(review.attributes.reviewCount === 4){
            rate4++;
          } else if(review.attributes.reviewCount === 5){
            rate5++;
          }
        });
        setStarsReview([rate1, rate2, rate3, rate4, rate5]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmitQuestion = async () => {
    setIsLoading(true);
    const crystalBallUrl = `${baseUrl}/api/tc-app/crystal-ball-query`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    // Send question to backend
    const response = await fetch(crystalBallUrl, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        userQuery: question,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      setCrystalBallQueryResult(data.response);
    } else {
      console.error('Error submitting question');
      setCrystalBallQueryResult(null);
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="px-14 flex justify-between items-center pt-8">
        <div className="">
          <h2 className="text-2xl font-bold text-white text-3xl">Dashboard</h2>
        </div>
      </div>
      <div className="px-14 py-4">
        {user.superAdmin && (
          <>
            <div className="my-4 rounded-lg border border-gray-200 bg-[#232333] p-8 shadow dark:border-gray-700 flex justify-center items-center">
              <div className="flex flex-col justify-center items-center min-w-[700px]">
                <h4 className="mb-6 text-3xl font-bold text-white">Crystal Ball</h4>
                
                <div className="space-y-6 w-full">
                  <div className="w-full">
                    <label className="block text-white mb-2">Enter your question:</label>
                    <input 
                      type="text" 
                      className="w-full p-3 rounded-lg bg-[#2b2c40] text-white border border-gray-700 focus:outline-none focus:border-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                      placeholder="How many claims did we do last month?"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !isLoading) {
                          handleSubmitQuestion();
                        }
                      }}
                      disabled={isLoading}
                    />
                  </div>

                  <button 
                    className={`px-6 py-2 bg-[#696cff] text-white rounded-lg hover:bg-[#5f61e6] transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} 
                    onClick={handleSubmitQuestion}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Submit'}
                  </button>

                  {isLoading && (
                    <div className="flex justify-center items-center">
                      <CircularProgress size="30px" /> 
                      <div className="ml-4">
                        <p className="text-white">Generating response...</p>
                      </div>
                    </div>
                  )}
                  
                  {crystalBallQueryResult && !isLoading && (
                    <div>
                      <label className="block text-white mb-2">Answer:</label>
                      <div className="bg-[#2b2c40] rounded-lg p-6 font-mono text-sm mb-4">
                        <pre className="text-white">
                          {crystalBallQueryResult.explanation}
                        </pre>
                      </div>
                    </div>
                  )}
                  </div>
              </div>
            </div>
            <iframe src="https://demo.trueclaim.ai/" width="100%" height="2800"></iframe>
            
            <div className="flex-row md:flex">
              <div className="mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:mb-0">
                <h1 className="mb-2 max-w-full text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Reviews</h1>
                <Review starsReview={starsReview} />
              </div>
              <div className="mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:mb-0">
                
              </div>
              <div className="mx-2 my-4 block flex-1 rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:mb-0">
                
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}