import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";
import {
  BaseHeaderLayout,
  ContentLayout,
  Alert,
  Button,
  Box,
  GridItem,
  Grid,
} from "@strapi/design-system";
import { Check } from "@strapi/icons";
import Input from "../../styled/base/Input/Input";
import qs from "qs";
import { LoadingSpinner } from "../ui/loading-spinner";

const INITIAL_FORM_ERRORS = {
  userName: false,
};

export default function IntergrationCCC() {
  const integrationsType = "CCC";
  const integrationsTypeCode = "ccc";
  const connectUrl = `${baseUrl}/api/integrations`;
  const token = useContext(GlobalContextState).token;
  const [status, setStatus] = useState(undefined);
  const [dataId, setDataId] = useState(null);
  const [dataUserName, setDataUserName] = useState("");
  const [dataPassword, setDataPassword] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
  const user = useContext(GlobalContextState).user;

  useEffect(() => {
    const initFetchData = async () => {
      await fetchFormData();
    };

    initFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchFormData() {
    try {
      setIsLoading(true);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const query = qs.stringify(
        {
          filters: {
            typeAccount: integrationsTypeCode,
            user: {
              username: {
                $eq: user.username,
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      const fetchUrl = `${connectUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: "GET",
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data.length > 0) {
          const configData = data.data[0];
          setDataId(configData.id);
          setDataUserName(configData.attributes.userName);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  function validateForm() {
    let hasErrors = false;
    if (dataUserName === "") {
      hasErrors = true;
      setFormError((prevState) => ({ ...prevState, userName: true }));
    } else {
      setFormError((prevState) => ({ ...prevState, userName: false }));
    }
    // if (dataPassword === '') {
    //     hasErrors = true;
    //     setFormError((prevState) => ({ ...prevState, password: true }));
    // } else {
    //     setFormError((prevState) => ({ ...prevState, password: false }));
    // }
    return hasErrors;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoadingSubmit(true);
    const hasErrors = validateForm();
    if (!hasErrors) {
      if (dataId !== null) {
        const response = await updateConfiguration();
        if (!response.ok) {
          setStatus({
            type: "error",
            message: "The username or password is incorrect.",
          });
        }
      } else {
        const response = await createNewConfiguration();
        if (!response.ok) {
          setStatus({
            type: "error",
            message: "The username or password is incorrect.",
          });
        }
      }
    }

    await fetchFormData();
    setLoadingSubmit(false);
  }

  async function updateConfiguration() {
    if (dataId !== null) {
      const newFormData = {
        userName: dataUserName,
        password: dataPassword,
      };

      const updateUrl = `${connectUrl}/${dataId}`;
      const response = await fetch(updateUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data: newFormData }),
      });

      return response;
    }
  }

  async function createNewConfiguration() {
    const newFormData = {
      userName: dataUserName,
      password: "",
      typeAccount: integrationsTypeCode,
      user: user.id,
    };

    const response = await fetch(connectUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data: newFormData }),
    });

    return response;
  }

  return (
    <div>
      <BaseHeaderLayout
        primaryAction={
          <Button
            onClick={handleSubmit}
            startIcon={<Check />}
            loading={loadingSubmit && true}
          >
            Save
          </Button>
        }
        title={`Connect to ${integrationsType}`}
        as="h2"
      />
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <ContentLayout>
          {dataId !== null && (
            <Alert className="mb-6" closeLabel="Close" variant="success">
              Your account connected to {integrationsType}
            </Alert>
          )}
          {dataId === null && (
            <Alert className="mb-6" closeLabel="Close" variant="warning">
              Your account is not connected to {integrationsType}
            </Alert>
          )}

          <Box color="neutral800" padding={6} background="neutral0">
            {status?.type === "success" && (
              <div>
                <Alert
                  className="mb-6"
                  closeLabel="Close"
                  title="Success"
                  variant="success"
                >
                  {status?.message}
                </Alert>
              </div>
            )}
            {status?.type === "error" && (
              <div>
                <Alert
                  className="mb-6"
                  closeLabel="Close"
                  title="Error"
                  variant="danger"
                >
                  {status?.message}
                </Alert>
              </div>
            )}
            <form
              className="form-label-style"
              autoComplete="off"
              role="presentation"
            >
              <Grid gap={5}>
                <GridItem col={6}>
                  <Input
                    id="userName"
                    className="mb-4"
                    name="userName"
                    type="text"
                    label="Email"
                    placeholder="Email"
                    autoComplete="false"
                    role="presentation"
                    onChange={(e) => setDataUserName(e.target.value)}
                    value={dataUserName}
                    error={formError.userName && "Please enter username."}
                  />
                </GridItem>
              </Grid>
            </form>
          </Box>
        </ContentLayout>
      )}
    </div>
  );
}
