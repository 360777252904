import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { GlobalContextDispatch } from "../../context/globalContext";
import { baseUrl } from "../../config";
import { BaseHeaderLayout, ContentLayout, Alert, Button, Box, GridItem, Grid } from "@strapi/design-system";
import { Check } from '@strapi/icons';
import Input from "../../styled/base/Input/Input";
import qs from 'qs';


const INITIAL_FORM_ERRORS = {
    mitchellUsername: false,
    mitchellPassword: false
};

export default function MitchellConection() {
  const user = useContext(GlobalContextState).user;
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [formError, setFormError] = useState(INITIAL_FORM_ERRORS);
  const [mitchellDataUsername, setMitchellDataUsername] = useState('');
  const [formData, setFormData] = useState({
    PRE_AI_COA_SEND: '0',
    PRE_AUTO_COA: '0',
    PRE_AUTO_EA: '0',
    EST_AUTO_OP: '0',
    POST_AI_ADAS_POST_EST_BUTTON: '0',
    POST_AI_ADAS_AI_ESTIMATOR: '0',
    POST_AI_REV_POST_EST_BUTTON: '0',
    POST_AI_REV_AI_ESTIMATOR: '0',
    POST_AUTO_ADAS: '0',
    POST_AUTO_REV: '0',
    POST_AUTO_PDF: '0'
  });

  useEffect(() => {
    if(user.mitchellUsername !== null){
        setMitchellDataUsername(user.mitchellUsername);
    }
  }, [user]);

  useEffect(() => {
    const initFetchData = async () => {
      await fetchConfigData();
    };

    initFetchData();
  }, []);

  const [status, setStatus] = useState(undefined);
  const token = useContext(GlobalContextState).token;
  const userUrl = `${baseUrl}/api/users/${user.id}`;
  const autoSaveUrl = `${baseUrl}/api/tc-app/auto-save-config`;
  const configUrl = `${baseUrl}/api/configurations`;


  const dispatch = useContext(GlobalContextDispatch);

  function validateForm(mcUserName, mcPassword){
    let hasErrors = false;
    if(mcUserName === ''){
        hasErrors = true;
        setFormError((prevState) => ({ ...prevState, mitchellUsername: true }));
    }else {
        setFormError((prevState) => ({ ...prevState, mitchellUsername: false }));
    }
    if(mcPassword === ''){
        hasErrors = true;
        setFormError((prevState) => ({ ...prevState, mitchellPassword: true }));
    }else {
        setFormError((prevState) => ({ ...prevState, mitchellPassword: false }));
    }
    return hasErrors;
  }
  
  async function fetchConfigData() {
    try {
      setLoadingSubmit(false);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const query = qs.stringify({
        filters: {
          user: {
            username: {
              $eq: user.username,
            },
          },
        },
      }, {
        encodeValuesOnly: true,
      });

      const fetchUrl = `${configUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if(data){
          const configData = data.data;
          const fetchedFormData = {
            PRE_AI_COA_SEND: '0',
            PRE_AUTO_COA: '0',
            PRE_AUTO_EA: '0',
            EST_AUTO_OP: '0',
            POST_AI_ADAS_POST_EST_BUTTON: '0',
            POST_AI_ADAS_AI_ESTIMATOR: '0',
            POST_AI_REV_POST_EST_BUTTON: '0',
            POST_AI_REV_AI_ESTIMATOR: '0',
            POST_AUTO_ADAS: '0',
            POST_AUTO_REV: '0',
            POST_AUTO_PDF: '0'
          };
          
          configData.forEach((config) => {
            fetchedFormData[config.attributes.configCode] = config.attributes.configValue;
          });

          setFormData(fetchedFormData);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingSubmit(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoadingSubmit(true);
    
    let mcUserName = document.getElementById("mitchellUsername").value;
    let mcPassword = document.getElementById("mitchellPassword").value;
    let hasErrors = validateForm(mcUserName, mcPassword);

    if(!hasErrors){
        let $apiUrl = 'https://api.apify.com/v2/actor-tasks/bouncy_ektara~mitchell-access-checker/run-sync?token=apify_api_sY3xrPgUhyqtmozFWfXVRmbULijEGc3ikVEw';
        let $data = {
            "username": mcUserName,
            "password": mcPassword
        };

        let $options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify($data)
        };

        const response = await fetch($apiUrl, $options);
        const responseJson = await response.json();
        
        if(responseJson.success === false){
            let message = 'The Mitchell username or password is incorrect.';
            setStatus({ type: 'error', message });
            setLoadingSubmit(false);
        }else {
            let message = 'Your account has been connected with Mitchell.';
            setStatus({ type: 'success', message });

            let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);

            let mitchellId = responseJson.bodyShop.id;
            let mitchellName = responseJson.bodyShop.name;
            let mitchellAccountNumber = responseJson.bodyShop.accountNumber;
            let mitchellOrgKey = responseJson.bodyShop.orgKey;
            let michellPrimaryUserName = responseJson.bodyShop.primaryUserName;

            let $dataSave = {
                "mitchellUsername": mcUserName,
                "mitchellPass": mcPassword,
                "mitchellId": mitchellId.toString(),
                "mitchellName": mitchellName,
                "mitchellAccountNumber": mitchellAccountNumber,
                "mitchellOrgKey": mitchellOrgKey,
                "michellPrimaryUserName": michellPrimaryUserName
            };

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify($dataSave)
            };

            fetch(userUrl, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then(function(data) {
                if (data && data.hasOwnProperty('error')) {
                    let message = data.error.message;
                    setStatus({ type: 'error', message: message}); 
                }else {
                    let message = 'Your account has been connected with Mitchell.';
                    setStatus({ type: 'success', message: message});
                    dispatch({ type: "LOGIN", payload: { jwt: token, user: data } });
                }
            })
            .catch((error) => {
                setStatus({ type: 'error', error });
            });

            setLoadingSubmit(false);
        }
    }
  }

  async function autoSave(target){
    const configName = target.name;
    const configValue = target.checked ? '1' : '0';
    setFormData((prevState) => ({ ...prevState, [configName]: configValue }));

    const response = await fetch(autoSaveUrl, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ 
        data: {
          configName: configName,
          configValue: configValue
        } 
      }),
    });
  }

  return (
    <div>
        <ContentLayout>
            {user.mitchellUsername !== null && (
                <Alert className="mb-6" closeLabel="Close" variant="success">
                    Your account connected to Mitchell
                </Alert>
            )}
            {user.mitchellUsername == null && (
                <Alert className="mb-6" closeLabel="Close" variant="danger">
                    Your account is not connect to Mitchell
                </Alert>
            )}

            <div className="rounded bg-gray-800 p-4 mb-6 mt-6">
              <h3 className="text-xl font-bold text-white text-3xl mr-4">Mitchell Connection</h3>
              <div className="max-w-md mt-4">
                {status?.type === "success" && (
                <div>
                    <Alert className="mb-6 mt-4" closeLabel="Close" title="Success" variant="success">
                        {status?.message}
                    </Alert>
                </div>
                )}
                {status?.type === "error" && (
                <div>
                    <Alert className="mb-6 mt-4" closeLabel="Close" title="Error" variant="danger">
                        {status?.message}
                    </Alert>
                </div>
                )}
              </div>
              <form className="form-label-style" autoComplete="off" role="presentation">
                <div className="max-w-md mt-4">
                  <Input
                      id="mitchellUsername"
                      className="mb-4"
                      name="mitchellUsername"
                      type="text"
                      label="Mitchell Username"
                      placeholder="Mitchell Username"
                      autoComplete="false"
                      role="presentation"
                      onChange={(e) => setMitchellDataUsername(e.target.value)}
                      value={mitchellDataUsername}
                      error={formError.mitchellPassword && "Please enter Mitchell username."} />
                </div>
                <div className="max-w-md mt-4">
                    <Input
                        id="mitchellPassword"
                        className="mb-4"
                        name="mitchellPassword"
                        type="password"
                        label="Mitchell Password"
                        autoComplete="new-password"
                        role="presentation"
                        error={formError.mitchellPassword && "Please enter Mitchell password."} />
                </div>
                <Button onClick={handleSubmit} startIcon={<Check />} loading={loadingSubmit && true} >Validate & Save</Button>
              </form>
            </div>

            <div className="rounded bg-gray-800 p-4 mb-6 mt-6">
                <h3 className="text-xl font-bold text-white text-3xl mr-4">PRE-Estimate Automations</h3>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.PRE_AI_COA_SEND} 
                      checked={formData.PRE_AI_COA_SEND === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="PRE_AI_COA_SEND"
                      id="PRE_AI_COA_SEND"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Auto Send to Car Owner SMS on Assignment reception</span>
                  </label>
                </div>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.PRE_AUTO_COA} 
                      checked={formData.PRE_AUTO_COA === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="PRE_AUTO_COA"
                      id="PRE_AUTO_COA"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Auto-Start Triaging based on info from Car Owner Assistant</span>
                  </label>
                </div>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.PRE_AUTO_EA} 
                      checked={formData.PRE_AUTO_EA === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="PRE_AUTO_EA"
                      id="PRE_AUTO_EA"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Auto-Start Triaging based on info from Estimator Assistant</span>
                  </label>
                </div>
            </div>

            <div className="rounded bg-gray-800 p-4 mb-6 mt-6">
              <h3 className="text-xl font-bold text-white text-3xl mr-4">EST Automations</h3>
              <div className="max-w-md mt-4">
                <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                  <input type="checkbox" value={formData.EST_AUTO_OP} 
                    checked={formData.EST_AUTO_OP === '1' ? true : false}
                    disabled={user.mitchellUsername === null ? true : false}
                    onChange={(e) => autoSave(e.target)}
                    name="EST_AUTO_OP"
                    id="EST_AUTO_OP"
                    className="sr-only peer" />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Automatically write Estimate</span>
                </label>
              </div>
            </div>

            <div className="rounded bg-gray-800 p-4 mb-6 mt-6">
                <h3 className="text-xl font-bold text-white text-3xl mr-4">POST-Estimate Automations</h3>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AI_ADAS_POST_EST_BUTTON} 
                      checked={formData.POST_AI_ADAS_POST_EST_BUTTON === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AI_ADAS_POST_EST_BUTTON"
                      id="POST_AI_ADAS_POST_EST_BUTTON"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Capture ADAS when Human Estimator clicks the POST-EST button</span>
                  </label>
                </div>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AI_ADAS_AI_ESTIMATOR} 
                      checked={formData.POST_AI_ADAS_AI_ESTIMATOR === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AI_ADAS_AI_ESTIMATOR"
                      id="POST_AI_ADAS_AI_ESTIMATOR"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Capture ADAS automatically after AI Estimator writes an estimate</span>
                  </label>
                </div>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AI_REV_POST_EST_BUTTON} 
                      checked={formData.POST_AI_REV_POST_EST_BUTTON === '1' ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AI_REV_POST_EST_BUTTON"
                      id="POST_AI_REV_POST_EST_BUTTON"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Capture Guidelines when Human Estimator clicks the POST-EST button</span>
                  </label>
                </div>
                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AI_REV_AI_ESTIMATOR} 
                      checked={formData.POST_AI_REV_AI_ESTIMATOR === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AI_REV_AI_ESTIMATOR"
                      id="POST_AI_REV_AI_ESTIMATOR"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Capture Guidelines automatically after AI Estimator writes an estimate</span>
                  </label>
                </div>

                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AUTO_ADAS} 
                      checked={formData.POST_AUTO_ADAS === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AUTO_ADAS"
                      id="POST_AUTO_ADAS"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Automatically write ADAS lines</span>
                  </label>
                </div>

                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AUTO_REV} 
                      checked={formData.POST_AUTO_REV === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AUTO_REV"
                      id="POST_AUTO_REV"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Automatically write Guidelines</span>
                  </label>
                </div>

                <div className="max-w-md mt-4">
                  <label className={`inline-flex items-center mb-5 ${user.mitchellUsername === null ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input type="checkbox" value={formData.POST_AUTO_PDF} 
                      checked={formData.POST_AUTO_PDF === '1' ? true : false}
                      disabled={user.mitchellUsername === null ? true : false}
                      onChange={(e) => autoSave(e.target)}
                      name="POST_AUTO_PDF"
                      id="POST_AUTO_PDF"
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Automatically attach Post-Estimate PDF Report</span>
                  </label>
                </div>
            </div>
        </ContentLayout>
    </div>
  );
}
