import MitchellConection from "../components/Account/MitchellConection";
import { Layout} from "@strapi/design-system";

export default function AccountSettingsMitchell() {
  const expandedSection = 'acc-2';

  return (
    <div>
      <Layout>
        <MitchellConection />
      </Layout>
    </div>
  );
}
