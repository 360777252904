import React, { useContext, useEffect, useState } from "react";
import qs from "qs";
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";

export default function Subscription() {
  const user = useContext(GlobalContextState).user;
  const urlStripeData = `${baseUrl}/api/stripe-customers`;
  const token = useContext(GlobalContextState).token;
  
  const [accountConfirmed, setAccountConfirmed] = React.useState(false);
  const [stripeData, setStripeData] = React.useState(null);
  const [currentPlan, setCurrentPlan] = React.useState('');
  const [selectedTime, setSelectedTime] = React.useState('monthly');
  const [currentPlanTokens, setCurrentPlanTokens] = React.useState(0);
  const [stripeProducts, setStripeProducts] = React.useState([]);
  const [deleteStatus, setDeleteStatus] = React.useState(null);
  const [changeSubscription, setChangeSubscription] = React.useState(false);
  const [popupCancelSubscription, setPopupCancelSubscription] = React.useState(false);
  const [updateSubsTokens, setUpdateSubsTokens] = React.useState(null);
  const [updateSubsPlan, setUpdateSubsPlan] = React.useState(null);
  const [updateSubsPriceId, setUpdateSubsPriceId] = React.useState(null);
  const [updateTotalAmout, setUpdateTotalAmout] = React.useState(null);
  const [updateSubs, setUpdateSubs] = React.useState(null);
  const [priceList, setPriceList] = React.useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [addingToken, setAddingToken] = useState(false);
  
  useEffect(() => {
    // Check if url has accountConfirmed query
    const urlParams = new URLSearchParams(window.location.search);
    const accountConfirmed = urlParams.get('email-confirmed');
    if(accountConfirmed === 'done'){
      setAccountConfirmed(true);

      setTimeout(() => {
        window.history.replaceState({}, document.title, window.location.pathname);
        setAccountConfirmed(false);
      } , 10000);
    }

    const initFetchData = async () => {
      await fetchStripeData();
      await fetchStripeProducts();
      await fetchPriceList();

      if(user.superAdmin){
        const url = `${baseUrl}/api/users`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAllUsers(data);
      }

    };

    initFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(deleteStatus === 'success'){
      const timerDelete = setTimeout(() => {
        setDeleteStatus(null);
      }, 5000);
      return () => clearTimeout(timerDelete);
    }
  }, [deleteStatus]);

  useEffect(() => {
    if(updateSubs === 'success'){
      const timerUpdate = setTimeout(() => {
        setUpdateSubs(null);
      }, 5000);
      return () => clearTimeout(timerUpdate);
    }
  }, [updateSubs]);

  async function fetchStripeData() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const query = qs.stringify({
        filters: {
            user: {
                username: {
                    $eq: user.username,
                },
            },
          },
      }, {
          encodeValuesOnly: true,
      });

      const fetchUrl = `${urlStripeData}?${query}`;
      const response = await fetch(fetchUrl, {
          method: 'GET',
          headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data.length > 0) {
          console.log('str', data.data[0]);
          setStripeData(data.data[0]);
          setCurrentPlan(data.data[0].attributes.subsPlan + '_' + data.data[0].attributes.subsTokens);
          setSelectedTime(data.data[0].attributes.subsPlan);
          setCurrentPlanTokens(data.data[0].attributes.subsTokens);
        }
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }

  async function fetchStripeProducts() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/api/stripe/products`, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.length > 0) {
          setStripeProducts(data);
        }
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }
  
  async function fetchPriceList() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/api/price-lists?filters[enable][$eq]=true`, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.data && data.data.length > 0) {
          let responsePriceList = data.data.map((item) => {
            return item.attributes;
          });
          console.log('responsePriceList', responsePriceList);
          setPriceList(responsePriceList);
        }
      }
    } catch (error) {
      console.error('Failed to fetch price list', error);
    }
  }

  function convertCurrency(amountInCents, currency) {
    const amountInDollars = amountInCents / 100;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amountInDollars);
  }

  async function startPayment(priceId, subsTokens, subsPlan, amount) {
    if(currentPlan){
      setChangeSubscription(true);
      setUpdateSubsTokens(subsTokens);
      setUpdateSubsPlan(subsPlan);
      setUpdateSubsPriceId(priceId);
      setUpdateTotalAmout(amount);
      return;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/create-checkout-session`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ 
        priceId: priceId,
        subsTokens: subsTokens,
        updateType: 'new',
        subsPlan: subsPlan,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if(data.url){
        window.location.href = data.url;
      }
    }
  }

  async function updatePaymentMethod() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/get-update-url-subscription`, {
      method: 'GET',
      headers: myHeaders
    });

    if (response.ok) {
      const data = await response.json();
      if(data.url){
        window.location.href = data.url;
      }
    }
  }

  async function changeSubscriptionPlan() {
    setChangeSubscription(false);

    let updateType = ''
    if(currentPlanTokens !== 0){
      updateType = 'downgrade'
      if(updateSubsTokens > currentPlanTokens){
        updateType = 'upgrade'
      }
    }
    
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/create-checkout-session`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ 
        priceId: updateSubsPriceId,
        subsTokens: updateSubsTokens,
        updateType: updateType,
        subsPlan: updateSubsPlan,
        totalAmount: updateTotalAmout,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if(data.subscriptionId){
        setUpdateSubs('success');
        setCurrentPlan(updateSubsPlan + '_' + updateSubsTokens);
      }
    }
  }

  async function cancelSubscription() {
    setPopupCancelSubscription(false);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/cancel-subscription`, {
      method: 'GET',
      headers: myHeaders
    });

    if (response.ok) {
      const data = await response.json();
      if(data.success){
        // Display success message
        setDeleteStatus('success');
        setCurrentPlan('');
      }
    }
  }

  async function updateUserToken() {
    setAddingToken(true);
    if(!user.superAdmin){
      setAddingToken(false);
      return;
    }

    const addToken = document.getElementById('addToken').value;
    const userToken = document.getElementById('user_token').value;
    const reason = document.getElementById('reason').value;

    if(addToken === '' || userToken === ''){
      setAddingToken(false);
      return;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/tc-app/admin-add-token`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        data: { 
          userId: userToken,
          tokens: addToken,
          reason: reason,
        }
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if(data.success){
        document.getElementById('addToken').value = '';
        document.getElementById('user_token').value = '';
        document.getElementById('reason').value = '';
      }
    }
    setAddingToken(false);
  }

  return (
    <>
      <div className="px-14 flex justify-between items-center pt-8">
        <div>
          <h2 className="text-2xl font-bold text-white text-3xl">Subscription</h2>
        </div>
        <div>
        {stripeData && (
        <button 
          onClick={() => updatePaymentMethod()}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">Update Payment Method</button>
        )}
        </div>
      </div>
      <div className="px-14 py-4">
        {accountConfirmed && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400" role="alert">
            <span className="font-medium">Your account has been confirmed. You can now choose a plan to get started.</span>
          </div>
        )}
        {!user.subscription && stripeData && (
          <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <span className="font-medium">We can't charge you because you don't have a payment method. Please add a payment method to your account.</span>
          </div>
        )}
        {deleteStatus === 'success' && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-800 dark:text-green-400" role="alert">
            <span className="font-medium">Your subscription has been cancelled successfully.</span>
          </div>
        )}
        {updateSubs === 'success' && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-800 dark:text-green-400" role="alert">
            <span className="font-medium">Your subscription has been changed successfully.</span>
          </div>
        )}
        <div className="rounded bg-gray-800 p-4 py-6 mb-6">
          <div className="w-full">
            <h2 className="text-3xl font-bold text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-snug dark:text-white lg:text-center 2xl:px-48">
              Choose the right plan for your business
            </h2>
            <p className="mb-8 text-lg text-gray-500 dark:text-gray-400 lg:text-center">Don't worry, you can change or cancel your plan at any time.</p>
            <div className="max-w-2xl mx-auto mb-8 text-center text-gray-500 dark:text-gray-400">
              <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                  <li className="w-full focus-within:z-10">
                      <a href="#" 
                        onClick={() => setSelectedTime('monthly')}
                        className={`inline-block w-full p-4 text-gray-900 border-r rounded-l-lg focus:outline-none text-white ${selectedTime === 'monthly' ? 'bg-gray-600' : 'bg-gray-700'}`}>Monthly</a>
                  </li>
                  <li className="w-full focus-within:z-10">
                      <a href="#" 
                        onClick={() => setSelectedTime('yearly')}
                        className={`inline-block w-full p-4 text-gray-900 border-r rounded-r-lg focus:outline-none text-white ${selectedTime === 'yearly' ? 'bg-gray-600' : 'bg-gray-700'}`}>Yearly</a>
                  </li>
              </ul> 
            </div>
            {selectedTime === 'monthly' ? (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 px-4">
                {stripeProducts.map((product, index) => {
                  if(product.active && product.metadata.plan === 'monthly'){
                    const thisPlan = product.metadata.plan + '_' + product.metadata.tokens;
                    return (
                      <div key={index} className={`mb-8 rounded-lg p-8 ${currentPlan === thisPlan ? 'bg-green-700' : 'bg-gray-700'}`}>
                        <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">
                          {product.name}
                        </h3>
                        <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                          {convertCurrency(product.default_price.unit_amount, product.default_price.currency)}
                        </span>
                        {stripeData && stripeData.attributes && currentPlan === thisPlan && (
                          <div className="mb-4"><strong className="w-40 inline-block">Next billing date:</strong> {stripeData.attributes.nextBillingDate}</div>
                        )}
                        <p className={`mb-4 text-sm ${currentPlan === thisPlan ? 'text-white' : 'dark:text-gray-400'}`}>
                          {product.description}&nbsp;
                        </p>
                        {currentPlan !== thisPlan ? (
                          <button 
                            onClick={() => startPayment(product.default_price.id, product.metadata.tokens, product.metadata.plan, product.default_price.unit_amount)}
                            className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">
                            {currentPlan !== '' ? 'Switch Plan' : 'Get Started'}
                          </button>
                        ) : (
                          <button 
                            onClick={() => setPopupCancelSubscription(true)}
                            className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-red-700 dark:bg-red-600 hover:bg-red-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-red-300 dark:hover:bg-red-700 md:mr-5 md:mb-0">
                            Cancel Subscription
                          </button>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 px-4">
                {stripeProducts.map((product, index) => {
                  if(product.active && product.metadata.plan === 'yearly'){
                    const thisPlan = product.metadata.plan + '_' + product.metadata.tokens;
                    return (
                      <div key={index} className={`mb-8 rounded-lg p-8 ${currentPlan === thisPlan ? 'bg-green-700' : 'bg-gray-700'}`}>
                        <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">
                          {product.name}
                        </h3>
                        <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                          {convertCurrency(product.default_price.unit_amount, product.default_price.currency)}
                        </span>
                        <p className={`mb-4 text-sm ${currentPlan === thisPlan ? 'text-white' : 'dark:text-gray-400'}`}>
                          {product.description}&nbsp;
                        </p>
                        {currentPlan !== thisPlan ? (
                          <button 
                            onClick={() => startPayment(product.default_price.id, product.metadata.tokens, product.metadata.plan, product.default_price.unit_amount)}
                            className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">
                            {currentPlan !== '' ? 'Switch Plan' : 'Get Started'}
                          </button>
                        ) : (
                          <button 
                            onClick={() => setPopupCancelSubscription(true)}
                            className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-red-700 dark:bg-red-600 hover:bg-red-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-red-300 dark:hover:bg-red-700 md:mr-5 md:mb-0">
                            Cancel Subscription
                          </button>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
        <div className="rounded bg-gray-800 p-4 py-6 mb-6">
          <div className="w-full">
            <h2 className="mb-4 text-3xl font-bold text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-snug dark:text-white lg:text-center 2xl:px-48">
              Pricing list
            </h2>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3 font-bold text-white">
                            Service Name
                            </th>
                            <th scope="col" className="px-6 py-3 font-bold text-white">
                            Description
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap w-[1%] font-bold text-white">
                            Success Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceList.map((item, index) => {
                          if(item.category === 'Pre'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">{item.serviceTitle}</td>
                                <td className="px-6 py-4">{item.serviceDescription}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                        {priceList.map((item, index) => {
                          if(item.category === 'Est'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">{item.serviceTitle}</td>
                                <td className="px-6 py-4">{item.serviceDescription}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                        {priceList.map((item, index) => {
                          if(item.category === 'Post'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">{item.serviceTitle}</td>
                                <td className="px-6 py-4">{item.serviceDescription}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                        {priceList.map((item, index) => {
                          if(item.category === 'Common'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">{item.serviceTitle}</td>
                                <td className="px-6 py-4">{item.serviceDescription}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                    </tbody>
                </table>
            </div>

          </div>
        </div>
        {user.superAdmin && (
          <div className="rounded bg-gray-800 p-4 pt-6">
            <div className="w-full">
              <h3 className="text-xl font-bold text-white text-3xl mr-4">Add tokens to user account</h3>
              <div className="mb-8 text-gray-500 dark:text-gray-400 max-w-[768px]">
              <div className="mb-5">
                <label for="addToken" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tokens</label>
                <input type="text" 
                  name="addToken" 
                  id="addToken" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
              </div>
              <div className="mb-5">
                <label for="user_token" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User</label>
                <select id="user_token" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  <option value="">Select User</option>
                  <option value={user.id}>Curent Account</option>
                  {allUsers && allUsers.map((userFilter) => (
                    !userFilter.superAdmin && (
                      <option key={userFilter.id} value={userFilter.id}>{`${userFilter.username} - ${userFilter.company}`}</option>
                    )
                  ))}
                </select>
              </div>
              <div className="mb-5">
                <label for="reason" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reason</label>
                <textarea id="reason" 
                  rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Reason"></textarea>
              </div>
              {addingToken ? (
                <button disabled type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                </svg>
                Adding...
                </button>
              ) : (
                <button type="button" 
                onClick={() => updateUserToken()}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
              )}
              </div>
            </div>
          </div>
        )}
      </div>
      {popupCancelSubscription && (
        <>
          <div id="popup-modal" tabindex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button 
                    onClick={() => setPopupCancelSubscription(false)}
                    type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to cancel your subscription?</h3>
                        <button data-modal-hide="popup-modal" 
                          onClick={() => cancelSubscription()}
                          type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            Yes, I'm sure
                        </button>
                        <button 
                          onClick={() => setPopupCancelSubscription(false)}
                          data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                    </div>
                </div>
            </div>
          </div>
          <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
        </>
      )}


      {changeSubscription && (
        <>
          <div id="popup-modal" tabindex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button 
                    onClick={() => setChangeSubscription(false)}
                    type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to change your subscription?</h3>
                        <button data-modal-hide="popup-modal" 
                          onClick={() => changeSubscriptionPlan()}
                          type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            Yes, I'm sure
                        </button>
                        <button 
                          onClick={() => setChangeSubscription(false)}
                          data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                    </div>
                </div>
            </div>
          </div>
          <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
        </>
      )}
    </>
  );
}
