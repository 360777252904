import { useState, useContext } from "react";
import { GlobalContextState } from '../context/globalContext';
import { Redirect } from 'react-router-dom';

import SigninForm from "../components/SigninForm/SigninForm";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import { Box } from "@strapi/design-system";

export default function Home() {
  const [selection, setSelection] = useState("signin");
  const { loggedIn } = useContext(GlobalContextState);
  
  if (loggedIn) return <Redirect to="/claims" />;
  
  return (
    <>
      <Box className="min-h-screen" paddingTop={2} paddingBottom={11} background="neutral100">
        {selection === "signin" && (
          <SigninForm setSelection={setSelection} />
        )}
        {selection === "forgot" && (
          <ForgotPassword setSelection={setSelection} />
        )}
      </Box>
    </>
  );
}
