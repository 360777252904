
import React, {useState, useEffect, useContext} from "react";
import { ContentLayout, BaseHeaderLayout } from "@strapi/design-system";
import { GlobalContextState } from "../../context/globalContext";
import { Helmet } from "react-helmet";
import YouTubePlayList from "./Document/YouTubePlayList";
import qs from 'qs';
import { baseUrl } from "../../config";

export default function DocumentView() {
  const [googleDriverId, setGoogleDriverId] = useState('');
  const [youtubeChannelId, setYoutubeChannelId] = useState('');
  const token = useContext(GlobalContextState).token;
  const getConfigsUrl = `${baseUrl}/api/tc-app/get-default-configs`;

  useEffect(() => {
    const fetchDocumentConfig = async () => {
      try {
        const configCodes = ["DEFAULT_GOOGLE_DRIVER_LIST_ID", ["DEFAULT_YOUTUBE_CHANNEL_ID"]];
        // Send POST request to get configuration data
        const response = await fetch(getConfigsUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ configCodes: configCodes }),
        });

        const data = await response.json();

        if(data) {
          const googleDriverId = data.DEFAULT_GOOGLE_DRIVER_LIST_ID;
          const youtubeChannelId = data.DEFAULT_YOUTUBE_CHANNEL_ID;
          if(googleDriverId !== 'DISABLED'){
            setGoogleDriverId(googleDriverId);
          }
          if(youtubeChannelId !== 'DISABLED'){
            setYoutubeChannelId(youtubeChannelId);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchDocumentConfig();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim - Documents</title>
      </Helmet>
      <div className="px-14 flex justify-between items-center pt-8">
        <div className="">
          <h2 className="text-2xl font-bold text-white text-3xl">Documents</h2>
        </div>
      </div>
      <div className="px-14 py-4">
        {youtubeChannelId && (
          <YouTubePlayList 
            youtubeChannelId={youtubeChannelId}  
          />
        )}
        {googleDriverId && (
          <div className="driver-google">
            <iframe src={`https://drive.google.com/embeddedfolderview?id=${googleDriverId}#list`} ></iframe>
          </div>
        )}
      </div>
    </>
  );
}