import React, { useState, useContext, useEffect } from "react";
import { GlobalContextState } from "../../context/globalContext";
import { baseUrl } from "../../config";
import qs from 'qs';

export default function Config() {

  const configUrl = `${baseUrl}/api/configurations`;
  const autoSaveUrl = `${baseUrl}/api/tc-app/auto-save-config`;

  const token = useContext(GlobalContextState).token;
  const user = useContext(GlobalContextState).user;
  const [estimaticAutomation, setEstimaticAutomation] = useState(false);
  const [formData, setFormData] = useState({
    EST_AI_OP: '0'
  });

  useEffect(() => {
    const initFetchData = async () => {
      await fetchConfigData();
    };

    initFetchData();

    if(user.automation){
      setEstimaticAutomation(true);
    }
  }, []);
  
  async function fetchConfigData() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const query = qs.stringify({
        filters: {
          user: {
            username: {
              $eq: user.username,
            },
          },
        },
      }, {
        encodeValuesOnly: true,
      });

      const fetchUrl = `${configUrl}?${query}`;
      const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if(data){
          const configData = data.data;
          const fetchedFormData = {
            EST_AI_OP: '0',
          };
          
          configData.forEach((config) => {
            fetchedFormData[config.attributes.configCode] = config.attributes.configValue;
          });

          setFormData(fetchedFormData);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function autoSave(target){
    const configName = target.name;
    const configValue = target.checked ? '1' : '0';
    setFormData((prevState) => ({ ...prevState, [configName]: configValue }));

    const response = await fetch(autoSaveUrl, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ 
        data: {
          configName: configName,
          configValue: configValue
        } 
      }),
    });
  }

  return (
    <>
      <div className="px-14 flex justify-between items-center pt-8">
        <div>
          <h2 className="text-2xl font-bold text-white text-3xl">Settings</h2>
        </div>
      </div>
      <div className="px-14 py-4">
        <div className="rounded bg-gray-800 p-4 mb-6 mt-6">
          <h3 className="text-xl font-bold text-white text-3xl mr-4">EST Automations</h3>
          <div className="max-w-md mt-4">
            <label className={`inline-flex items-center mb-5 cursor-pointer`}>
              <input type="checkbox" value={formData.EST_AI_OP} 
                checked={formData.EST_AI_OP === '1' ? true : false}
                onChange={(e) => autoSave(e.target)}
                name="EST_AI_OP"
                id="EST_AI_OP"
                className="sr-only peer" />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Automatic Triage</span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
}