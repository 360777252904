import { Switch, Route } from "react-router-dom";
import React, { useState } from "react";
import { Box } from "@strapi/design-system";
import DocumentView from '../components/Dashboard/DocumentView';
import { Helmet } from "react-helmet";
import Header from "../components/Header";

export default function Document() {
  const menuActive = 'documents';
  const [leftNavVisible, setLeftNavVisible] = useState(false);
  return (
    <Box className="dark bg-gray-900">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Documents</title>
      </Helmet>
      <Header 
        leftNavVisible={leftNavVisible} 
        setLeftNavVisible={setLeftNavVisible} 
        menuActive={menuActive}
      />
      <main className={`${leftNavVisible? 'ml-64' : ''} transition-all flex-1 pt-[73px]`}>
        <Switch>
          <Route exact path="/documents" render={() => <DocumentView />} />
        </Switch>
      </main>
    </Box>
  );
}
