//Default react
import IntergrationProgiParts from "../../components/Account/IntergrationProgiParts";
import IntergrationAudatex from "../../components/Account/IntergrationAudatex";
import IntergrationCCC from "../../components/Account/IntergrationCCC";
import IntergrationPartsTrader from "../../components/Account/IntergrationPartsTrader";
import IntergrationProgiSync from "../../components/Account/IntergrationProgiSync";
import IntergrationRepairCenter from "../../components/Account/IntergrationRepairCenter";
import IntergrationAlldata from "../../components/Account/IntergrationAlldata";
import { Layout} from "@strapi/design-system";

export default function AccountSettingsIntergrations({ typeAccount }) {
  const expandedSection = 'acc-2';

  return (
    <div>
      <Layout>
        {typeAccount === 'progiParts' && (
          <IntergrationProgiParts />
        )}
        {typeAccount === 'progiSync' && (
          <IntergrationProgiSync />
        )}
        {typeAccount === 'audatex' && (
          <IntergrationAudatex />
        )}
        {typeAccount === 'ccc' && (
          <IntergrationCCC />
        )}
        {typeAccount === 'partsTrader' && (
          <IntergrationPartsTrader />
        )}
        {typeAccount === 'repairCenter' && (
          <IntergrationRepairCenter />
        )}
        {typeAccount === 'alldata' && (
          <IntergrationAlldata />
        )}
      </Layout>
    </div>
  );
}
