import React, { useState, useRef } from 'react';

const ChatForm = () => {
  const MAX_PHOTOS = 30;
  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [cameraOpen, setCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Open the camera with the selected facing mode (environment)
  const openCamera = () => {
    setCameraOpen(true);
    const constraints = {
      video: {
        facingMode: 'environment',
        width: { ideal: 1920 }, // Full HD width
        height: { ideal: 1080 }, // Full HD height
        frameRate: { ideal: 30 }, // Optional: frame rate for better quality
      },
      audio: false,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => {
        console.error('Error accessing the camera:', error);
      });
  };

  // Take photo and append to the captured photos list
  const takePhoto = () => {
    if (capturedPhotos.length >= MAX_PHOTOS) {
      alert(`You have reached the maximum limit of ${MAX_PHOTOS} photos.`);
      closeCamera(); // Close the camera when the limit is reached
      return;
    }

    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;

    // Adjust canvas dimensions to match video resolution
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    
    // Convert canvas image to Blob and save it as a file
    canvasRef.current.toBlob(
      (blob) => {
        const file = new File([blob], `photo-${Date.now()}.png`, { type: 'image/png' });
        setCapturedPhotos((prevPhotos) => [...prevPhotos, file]);
      },
      'image/png',
      1.0 // Quality factor set to maximum (1.0 for highest quality)
    );
  };

  // Close the camera
  const closeCamera = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setCameraOpen(false);
  };

  // Remove a captured photo from the list
  const removePhoto = (index) => {
    const updatedPhotos = capturedPhotos.filter((_, i) => i !== index);
    setCapturedPhotos(updatedPhotos);
  };

  return (
    <div className="chat-form relative">
      {/* Button to open the camera */}
      {!cameraOpen && (
        <button
          onClick={openCamera}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white p-4 rounded-lg z-50"
        >
          Take Pictures
        </button>
      )}

      {/* Camera open state */}
      {cameraOpen && (
        <div className="z-50 flex align-center justify-center fixed bottom-10 left-1/2 transform -translate-x-1/2">
          {/* Button to take photos */}
          <button
            onClick={takePhoto}
            className="bg-green-500 text-white p-4 rounded-lg z-50"
            disabled={capturedPhotos.length >= MAX_PHOTOS}
          >
            Take Photo
          </button>

          {/* Button to close the camera */}
          <button
            onClick={closeCamera}
            className="bg-red-500 text-white p-4 rounded-lg z-50 ml-4"
          >
            Close Camera
          </button>
        </div>
      )}

      {/* Camera feed */}
      {cameraOpen && (
        <div>
          <video
            playsInline
            className="fixed inset-x-0 inset-y-0 w-full h-full z-40 object-cover"
            ref={videoRef}
          />
          <canvas
            className="fixed inset-x-0 inset-y-0 w-full h-full z-30 opacity-0"
            ref={canvasRef}
          />
          <p className="fixed top-0 mt-2 left-1/2 transform -translate-x-1/2 z-50 text-white">
            {capturedPhotos.length} / {MAX_PHOTOS} photos taken
          </p>
        </div>
      )}

      {/* Display captured photos */}
      <div className="captured-photos fixed z-50 top-0">
        {capturedPhotos.map((photo, index) => (
          <div key={index} className="photo-preview">
            <img
              src={URL.createObjectURL(photo)}
              alt={`Captured Photo ${index + 1}`}
              style={{ width: '100px', height: '100px', margin: '5px' }}
            />
            <button
              onClick={() => removePhoto(index)}
              className="bg-gray-500 text-white p-1 rounded"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatForm;