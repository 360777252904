import React, { useState, useEffect  } from "react";
import { 
  Grid,
  GridItem,
  Box,
  BaseCheckbox, Table, Thead, Tbody, Tr, Td, Th, Typography
} from "@strapi/design-system";

import Slider from '@mui/material/Slider';
import { Expand, Cross } from "@strapi/icons";

const ImageGallery = (props) => {
  const { checkedDamages, 
          s3Data, 
          updateCheckedDamages, 
          updateLogData, 
          selectedPoi, 
          setSelectedPoi,
          reportData,
          reportPartsAvailable,
          poiParts,
          colorMapping,
          labelWithKey,
          reportDataOpLines,
          opLinesDiff,
          COLORS,
          setCheckedDamages,
          defaultDamagePoi,
          impactOccur
        } = props;
  const [originImagesData, setOriginImagesData] = useState([]);
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [filterValue, setFilterValue] = useState([0, 100]);
  const [selectedImageMasks, setSelectedImageMasks] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImagePoi, setSelectedImagePoi] = useState('');
  const [allImages, setAllImages] = useState(false);
  const minDistance = 10;

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 40,
      label: '40%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  function downloadAllImages() {
    imageUrls.map((url) => {
      window.open(url);
    });
  };

  useEffect(() => {
    let imagesData = [];
    let availableImages = [];
    let allImages = [];

    if (s3Data.resultImages) {
      if(s3Data.resultImages.length != 0){
        Object.keys(s3Data.resultImages).map((part, indexPart) => {
          s3Data.resultImages[part].map((images, index) => [
            Object.keys(images).map((key, index) => {
              if (key === 'name' && images['name']) {
                imagesData.push({
                  url: images['original'],
                  name: images['name'],
                  masks: images['masks'],
                  index_position: part,
                });
                availableImages.push(images['name']);
                allImages.push(images['original']);
              }
            })
          ])
        });
      }
    }
    setAvailableImages(availableImages);
    setImageUrls(allImages);
    setOriginImagesData(imagesData);
  },[s3Data]);
  
  useEffect(() => {
    const selectedImageData = originImagesData[selectedImage];
    if(selectedImageData && availableImages.includes(selectedImageData['name'])){
      setSelectedImageMasks(selectedImageData);
      updateLogData(selectedImageData['name']);
    }else {
      // Get fist available image
      let firstAvailableImage = availableImages[0];
      let firstAvailableImageIndex = originImagesData.findIndex((image) => image['name'] === firstAvailableImage);
      setSelectedImageMasks(firstAvailableImageIndex);
      updateLogData(availableImages[0]);
    }
  }, [selectedImage, originImagesData]);

  useEffect(() => {
    handlePartMaskingMask();
  }, [checkedDamages, filterValue]);

  useEffect(() => {
    if(Object.values(selectedPoi).every((value) => value === false) || Object.values(selectedPoi).every((value) => value === true)){
      let availableImages = [];
      originImagesData.map((slide, index) => {
        availableImages.push(slide['name']);
      });
      setAvailableImages(availableImages);

      // If selectedImage is not undefined 
      let sltImg = 0;
      if(selectedImage !== undefined){
        sltImg = selectedImage;
      }
      if(originImagesData[sltImg]){
        setSelectedImageMasks(originImagesData[sltImg]);
      }else {
        setSelectedImageMasks(originImagesData[0]);
      }
    }else {
      let availableImages = [];
      originImagesData.map((slide, index) => {
        if(selectedPoi[slide.index_position]){
          availableImages.push(slide['name']);
        }
      });

      // If selectedImage is not undefined 
      let sltImg = 0;
      if(selectedImage !== undefined){
        sltImg = selectedImage;
      }
      if(originImagesData[sltImg]){
        let selectedImg = originImagesData[sltImg];
        if(!availableImages.includes(selectedImg['name'])){
          let firstAvailableImage = availableImages[0];
          let firstAvailableImageIndex = originImagesData.findIndex((image) => image['name'] === firstAvailableImage);
          setSelectedImage(firstAvailableImageIndex);
          updateLogData(firstAvailableImage);
          setSelectedImageMasks(originImagesData[firstAvailableImageIndex]);
        }
        setAvailableImages(availableImages);
      }
    }
  },[selectedPoi, originImagesData]);

  function removePartMaskingMask() {
    // Remove all existing part masking canvases
    const existingCanvases = document.querySelectorAll(".main-img-wrapper .part-mask-canvas");
    existingCanvases.forEach((canvas) => canvas.remove());
    return;
  }

  function resetFilterImages() {
    setAllImages(true);
    setFilterValue([0, 100]);
    setSelectedPoi({
      'Front': false,
      'Front_Left': false,
      'Front_Right': false,
      'Rear': false,
      'Rear_Left': false,
      'Rear_Right': false,
      'Left': false,
      'Right': false,
    });

    setCheckedDamages({
      'all': true,
      'original': true,
      'dent': true,
      'parts': false,
      'scratch': true,
      'tear': true,
    });
  }

  function poiFilterImages() {
    setAllImages(false);
    setFilterValue([0, 100]);
    setSelectedPoi(defaultDamagePoi);

    setCheckedDamages({
      'all': true,
      'original': true,
      'dent': true,
      'parts': false,
      'scratch': true,
      'tear': true,
    });
  }
 
  function handlePartMaskingMask() {
    removePartMaskingMask();

    const selectedImageData = originImagesData[selectedImage];
    if(selectedImageData){
      const maskingData = selectedImageData['masks']; 
      // Skip if PartMaskingResult is empty object or PartMaskingResult.result is empty array
      // Loop through the maskingData
      let ddMarkingData = [];
      Object.keys(maskingData).map((key, index) => {
        if(maskingData[key].length > 0 && checkedDamages[key]) {
          // Merge ddMarkingData with maskingData[key]
          ddMarkingData = ddMarkingData.concat(maskingData[key]);
        }
      });
  
      // Get the original image
      const orgImg = document.getElementById("step3_main_img");
      // Loop through the segments, create a canvas for each segment and draw the segment on the canvas
      const segmentsData = ddMarkingData;
      segmentsData.forEach((segment, index) => {
        const { name, confidence, box, segments } = segment;
        if(name !== 'Scratch' && name !== 'Dent' && name !== 'Tear') {
          var maxConfidence = 1;
          var minConfidence = 0;
        } else {
          var maxConfidence = filterValue[1] / 100;
          var minConfidence = filterValue[0] / 100;
        }

        if (confidence <= maxConfidence && confidence >= minConfidence) {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
    
          let orgImg = document.getElementById("step3_main_img");
          canvas.height = orgImg.height;
          let orgImgWidth = orgImg.naturalWidth;
          let orgImgHeight = orgImg.naturalHeight;
          let ratio = orgImgWidth / orgImgHeight;
          canvas.width = canvas.height * ratio;
    
          // Canvas color
          if(name !== 'Scratch' && name !== 'Dent' && name !== 'Tear') {
            ctx.fillStyle = `rgba(${COLORS[index % COLORS.length].r}, ${COLORS[index % COLORS.length].g}, ${COLORS[index % COLORS.length].b}, 0.7)`;
            ctx.strokeStyle = `rgba(${COLORS[index % COLORS.length].r}, ${COLORS[index % COLORS.length].g}, ${COLORS[index % COLORS.length].b}, 1)`;
          }else {
            ctx.fillStyle = `rgba(255,0,0,0.4)`;
            ctx.strokeStyle = `rgba(255,0,0,1)`;
          }
          drawSegments(canvas, ctx, [segment], orgImgWidth, orgImgHeight, name);
          // Set canvas id to the segment name
          canvas.id = `${name}_${index}`;
          // Set canvas name to the segment name. Replace underscore and dash with space. Capitalize the first letter of each word.
          const nameFormatted = name.replace(/_|-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
          canvas.setAttribute("data-name", nameFormatted);
          canvas.className = "part-mask-canvas";
          // Append the canvas to the main image wrapper
          document.querySelector(".main-img-wrapper .main-image-sqrimg").appendChild(canvas);
        }
      });
    }
  }

  function drawSegments(canvas, ctx, segmentsData, orgImgWidth, orgImgHeight, name) {
    const screenScaleX = canvas.width / orgImgWidth;
    const screenScaleY = canvas.height / orgImgHeight;

    segmentsData.forEach((segment) => {
      const { name, confidence, box, segments } = segment;

      
      let confidenceScore = Math.round(confidence * 100) / 100;
      if(name !== 'Scratch' && name !== 'Dent' && name !== 'Tear') {
        var stringDamage = name;
      }else {
        var stringDamage = name + ' ' + confidenceScore;
      }

      const scaleX = screenScaleX;
      const scaleY = screenScaleY;
      ctx.strokeRect(
        box.x1 * scaleX,
        box.y1 * scaleY,
        (box.x2 - box.x1) * scaleX,
        (box.y2 - box.y1) * scaleY
      );

      // Draw text
      drawTextBG(ctx, stringDamage, "16px Arial", box.x1 * scaleX, box.y1 * scaleY - 15);

      // Draw segments
      ctx.beginPath();
      segments.x.forEach((x, i) => {
        const y = segments.y[i];
        if (i === 0) {
          ctx.moveTo(x * scaleX, y * scaleY);
        } else {
          ctx.lineTo(x * scaleX, y * scaleY);
        }
      });
      ctx.closePath();
      ctx.fill();
    });
  }

  function drawTextBG(ctx, txt, font, x, y) {   
    ctx.save();
    ctx.font = font;
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#ff0000';
    var width = ctx.measureText(txt).width + 5;
    ctx.fillRect(x, y, width, parseInt(font, 10));
    ctx.fillStyle = '#fff';
    ctx.fillText(txt, x, y);
    ctx.restore();
  }

  function updateSelectedImage(index) {
    setSelectedImage(index);
  }

  function handlePartThumbMaskingMask(imageName) {
    let currentImageData = null;
    let parent = null;
    originImagesData.map((image, index) => {
      if(image['name'] === imageName){
        currentImageData = image;
        parent = document.getElementById(`step3_main_container_thumb_${index}`);
      }
    });

    if(currentImageData && parent){
      const image = parent.querySelector('.step3_main_img_thumb[data-key="'+imageName+'"]');
      const imgHeight = image.height;
      const imgWidth = image.width;
      const orgImgWidth = image.naturalWidth;
      const orgImgHeight = image.naturalHeight;
      let canGenerate = false
      if(imgHeight > 0 && imgWidth > 0 && orgImgWidth > 0 && orgImgHeight > 0){
        canGenerate = true;
      }
      if(!canGenerate){
        return;
      }

      parent.querySelectorAll('.part-mask-canvas').forEach((canvas) => canvas.remove());
      let maskingData = currentImageData['masks']; 
      // Skip if PartMaskingResult is empty object or PartMaskingResult.result is empty array
      // Loop through the maskingData
      let ddMarkingData = [];
      Object.keys(maskingData).map((key, index) => {
        if(key !== 'parts'){
          if(maskingData[key].length > 0 && checkedDamages[key]) {
            // Merge ddMarkingData with maskingData[key]
            ddMarkingData = ddMarkingData.concat(maskingData[key]);
          }
        }
      });

      // Loop through the segments, create a canvas for each segment and draw the segment on the canvas
      let segmentsData = ddMarkingData;
      segmentsData.forEach((segment, index) => {
        let { name, confidence, box, segments } = segment;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        
        canvas.height = imgHeight;
        canvas.width = imgWidth;
  
        ctx.fillStyle = `rgba(255,0,0,0.4)`;
        ctx.strokeStyle = `rgba(255,0,0,1)`;
        drawThumbSegments(canvas, ctx, [segment], orgImgWidth, orgImgHeight, name);
        // Set canvas id to the segment name
        canvas.id = `${name}_${index}`;
        canvas.className = "part-mask-canvas";
        parent.appendChild(canvas);
      });
    }
  }
  
  function drawThumbSegments(canvas, ctx, segmentsData, orgImgWidth, orgImgHeight, name) {
    const screenScaleX = canvas.width / orgImgWidth;
    const screenScaleY = canvas.height / orgImgHeight;

    segmentsData.forEach((segment) => {
      const { name, confidence, box, segments } = segment;

      const scaleX = screenScaleX;
      const scaleY = screenScaleY;
      // Draw segments
      ctx.beginPath();
      segments.x.forEach((x, i) => {
        const y = segments.y[i];
        if (i === 0) {
          ctx.moveTo(x * scaleX, y * scaleY);
        } else {
          ctx.lineTo(x * scaleX, y * scaleY);
        }
      });
      ctx.closePath();
      ctx.fill();
    });
  }

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setFilterValue([Math.min(newValue[0], filterValue[1] - minDistance), filterValue[1]]);
    } else {
      setFilterValue([filterValue[0], Math.max(newValue[1], filterValue[0] + minDistance)]);
    }
  };

  const handleZoomEffect = () => {
    // Toogle class active to main-img-container
    const mainImgContainer = document.querySelector(".main-img-container");
    mainImgContainer.classList.toggle("active");
  };

  return (
    <>
      {originImagesData.length > 0 && (
        <GridItem col={12} background="neutral100" shadow="filterShadow">
          <Grid className="main-img-container">
            <GridItem col={12} className="top-cl-galery px-2">
              <div className="light-box-images-thumb">
                <div className="light-box-thumb active">
                  <div className="images-actions">
                  <button 
                    onClick={() => {downloadAllImages()}}
                    className="btn btn-primary">Download All</button>
                  {allImages ? (
                    <button 
                        onClick={() => {poiFilterImages()}}
                      className="btn btn-primary ml-2">POI Images</button>
                  ) : (
                    <button 
                      onClick={() => {resetFilterImages()}}
                    className="btn btn-primary ml-2">All Images</button>
                  )}
                  </div>
                </div>
                {originImagesData.map((image, index) => (
                  <div key={index} className={availableImages.includes(image['name']) ? 'active light-box-thumb' : 'light-box-thumb'}
                      onClick={() => updateSelectedImage(index)}>
                    <div className="sqrimg" id={`step3_main_container_thumb_${index}`}>
                    <img
                      className="step3_main_img_thumb"
                      src={image['url']}
                      data-index={index}
                      data-key={image['name']}
                      onLoad={() => handlePartThumbMaskingMask(image['name'])}
                      alt=""
                    />
                    </div>
                  </div>
                ))}
            </div>
            </GridItem>
            <GridItem col={6} className="left-cl-galery">
              <div className="main-image-cross" onClick={handleZoomEffect}><Cross /></div>
              <Box
                className="h-full left-container-galery"
                padding={4}
                borderRadius>
                <div className="main-img-wrapper">
                  {selectedImageMasks && (
                    <>
                      <div className="main-image-sqrimg">
                        <div className="main-image-zoom"
                          onClick={handleZoomEffect}><Expand /></div>
                        <img
                          src={selectedImageMasks['url']}
                          id="step3_main_img"
                          alt=""
                          onLoad={handlePartMaskingMask}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="light-box-container">
                  <ul className="filter-image">
                    <li className="filter-image-title">
                      <label className="title_filter">Damages:</label>
                    </li>
                    <li>
                      <BaseCheckbox id="child_dent" name="child_dent" value={checkedDamages['dent']} onChange={(e) => {updateCheckedDamages('dent', e.target.checked)}} />
                      <label htmlFor="child_dent">Dent</label>
                    </li>
                    <li>
                      <BaseCheckbox id="child_scratch" name="child_scratch" value={checkedDamages['scratch']} onChange={(e) => {updateCheckedDamages('scratch', e.target.checked)}} />
                      <label htmlFor="child_scratch">Scratch</label>
                    </li>
                    <li>
                      <BaseCheckbox id="child_tear" name="child_tear" value={checkedDamages['tear']} onChange={(e) => {updateCheckedDamages('tear', e.target.checked)}} />
                      <label htmlFor="child_tear">Tear</label>
                    </li>
                    <li>
                      <BaseCheckbox id="child_all" name="child_all" value={checkedDamages['all']} onChange={(e) => {updateCheckedDamages('all', e.target.checked)}} />
                      <label htmlFor="child_all">All</label>
                    </li>
                    <li className="end-parts">
                      <BaseCheckbox id="child_parts" name="child_parts" value={checkedDamages['parts']} onChange={(e) => {updateCheckedDamages('parts', e.target.checked)}} />
                      <label htmlFor="child_parts">Parts</label>
                    </li>
                  </ul>
                  <div className="damage-score-filter"> 
                    <label className="title_filter">Confidence Score:</label>
                    <Slider defaultValue={100} 
                      getAriaLabel={() => 'Filter Confidence Score'}
                      className="slider-horizontal"
                      valueLabelDisplay="auto"
                      value={filterValue}
                      orientation="horizontal"
                      marks={marks}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Box>
            </GridItem>
            <GridItem col={6} className="right-cl-galery">
              <Box
                className="h-full"
                borderRadius>

                  {reportData.status === "success" && (
                    //Loop through the object reportData.reportJson and display the data with the color mapping in a table
                    <div className="digram-table no-hover-effect">
                    <Table
                      colCount="2"
                      rowCount={
                        Object.keys(reportData.reportJson).length + 1
                      }
                      background="neutral200"
                    >
                      <Thead>
                        <Tr>
                          <Th>
                            <Typography variant="sigma">Part</Typography>
                          </Th>
                          <Th>
                            <Typography variant="sigma">Analysis</Typography>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Object.entries(reportData.reportJson).map(
                          ([key, value]) => (
                            reportPartsAvailable.includes(key) && (
                              <Tr className={poiParts[key]}  key={key}>
                                <Td>
                                  <p>
                                    <span className={`${key} ${colorMapping[key]}`}>
                                      {labelWithKey[key] ? labelWithKey[key] : key}
                                    </span>
                                  </p>
                                  <p>
                                    <Typography className="whitespace-pre-wrap">
                                      {reportDataOpLines[key] && reportDataOpLines[key].operation ? reportDataOpLines[key].operation : ""}
                                    </Typography>
                                  </p>
                                  {reportDataOpLines[key] && reportDataOpLines[key].units && (
                                    <p>
                                      <Typography className="whitespace-pre-wrap">
                                        Unit: {reportDataOpLines[key].units.toFixed(2)}
                                      </Typography>
                                    </p>
                                  )}
                                </Td>
                                <Td>
                                  <Typography className="whitespace-pre-wrap">
                                    {
                                      // Display if value is not an object
                                      typeof value !== "object"
                                        ? value
                                        : 'N/A'
                                    }
                                  </Typography>
                                </Td>
                              </Tr>
                            )
                          )
                        )}
                        {opLinesDiff && (
                          opLinesDiff.map((opLine, index) => (
                            <Tr key={index}>
                              <Td>
                                <span>
                                  {labelWithKey[opLine.realPart] ? labelWithKey[opLine.realPart] : opLine.realPart}
                                </span>
                              </Td>
                              <Td>
                                <Typography className="whitespace-pre-wrap">
                                  {opLine.part}
                                </Typography>
                              </Td>
                              <Td>
                                <Typography className="whitespace-pre-wrap">
                                  {opLine.operation}
                                </Typography>
                              </Td>
                              <Td>
                                <Typography className="whitespace-pre-wrap">
                                  {opLine.units ? opLine.units.toFixed(2) : ""}
                                </Typography>
                              </Td>
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                    </div>
                  )}
              </Box>
            </GridItem>
          </Grid>
        </GridItem>
      )}
    </>
  );
}

export default ImageGallery;
