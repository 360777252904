import React from "react";
import {
  Box,
  Typography,
  GridItem,
} from "@strapi/design-system";

const Trail = (props) => {
  const {claimTrail, formatDateData, showTrail, dropdownRef} = props;
  
  return (
    <>
      {claimTrail && showTrail && (
        <GridItem className="tc-dmif-trail" ref={dropdownRef}>
          <Box
              className="trail-list"
              padding={4}
              background="neutral100"
              shadow="filterShadow"
              borderRadius
            >
              <Box padding={2}>
                <div className="trail-data-list">
                {claimTrail.map((trail, index) => (
                    <div key={index} className="trail-data-item">
                      <div className="trail-action">UPDATE</div>
                      <div>
                        <div className="trail-time">{formatDateData(trail.timestamp)}</div>
                        <div className="trail-data-change whitespace-pre-wrap">{trail.event}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </Box>
          </Box>
        </GridItem>
          )}
    </>
  );
};

export default Trail;