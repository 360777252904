import React, { useState, useContext } from 'react';
import { GlobalContextState } from '../context/globalContext';
import { Check } from '@strapi/icons';
import { Helmet } from 'react-helmet';
import Textarea from "../styled/base/Textarea/Textarea";

import {
  Button,
  BaseHeaderLayout,
  Box,
  TextInput,
  ContentLayout,
  Layout,
  Alert,
  Typography,
} from "@strapi/design-system";

const AccountDeletionForm = () => {
  const user = useContext(GlobalContextState).user;
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formData, setFormData] = useState({
    email: user.email,
    reason: '',
    feedback: '',
  });
  const [status, setStatus] = useState(null);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.reason) newErrors.reason = 'Reason is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoadingSubmit(true);
      setErrors({});
    }
    
    const randomTime = Math.floor(Math.random() * 400) + 800;

    setTimeout(() => {
      setLoadingSubmit(false);
      setStatus({
        type: "success",
        message: "Account deletion request submitted successfully. You will receive an email confirmation shortly.",
      });
    }, randomTime);
  };

  return (
    <>
    <Box background="neutral100">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trueclaim App - Account Deletion Request</title>
      </Helmet>
      <Layout>
        <main className="flex-1">
            <BaseHeaderLayout
              title="Account Deletion Request Form"
              as="h2"
              primaryAction={
                <Button
                  onClick={handleSubmit}
                  loading={loadingSubmit}
                  startIcon={<Check />}
                >
                  Submit Request
                </Button>
              }
            />
            <ContentLayout>
                <Box color="neutral800" padding={6} background="neutral0" className="custom-form-delete-account">
                  {status?.type === "success" && (
                    <Alert marginBottom={6} closeLabel="Close" variant="success">
                      {status?.message}
                    </Alert>
                  )}

                  <div className="max-w-md leading-6">
                    <Typography variant="beta" className="mb-4">We're sorry to see you go!</Typography><br /><br />
                    <Typography variant="body">
                      Please provide a reason for deleting your account. Your account will be deleted within 30 days of submitting this request.
                    </Typography>
                    <Box marginBottom={6}>
                      <TextInput
                        label="Email"
                        name="email"
                        value={formData.email}
                        disabled
                      />

                      <Textarea
                        id="reason"
                        name="reason"
                        rows="4"
                        type="textarea"
                        label="Reason for Account Deletion"
                        placeholder="Enter reason for account deletion"
                        onChange={handleChange}
                        value={formData.reason}
                        error={
                          errors.reason && "Please provide a reason"
                        }
                      />

                      <Textarea
                        id="feedback"
                        name="feedback"
                        rows="4"
                        type="textarea"
                        label="Additional Feedback (Optional)"
                        placeholder="Enter additional feedback"
                        value={formData.feedback}
                        onChange={handleChange}
                      />
                    </Box>
                  </div>
                </Box>
              </ContentLayout>
        </main>
      </Layout>
    </Box>
    </>
  );
};

export default AccountDeletionForm;
